"use client";

import React, { FC, useEffect, useRef, useState } from "react";
import Image from "next/image";
import useInterval from "beautiful-react-hooks/useInterval";
import useHorizontalSwipe from "beautiful-react-hooks/useHorizontalSwipe";
import useBannerStore from "@/store/banner/banner.store";
import { useStore } from "zustand";
import { IBanner } from "@/interfaces/banner.interface";
import { useIsMobile } from "@/hooks/useMobile";

export interface LandingHeroProps {
  className?: string;
}

let TIME_OUT: NodeJS.Timeout | null = null;

const LandingHero: FC<LandingHeroProps> = ({ className = "" }) => {
  const ref = useRef<HTMLDivElement>(null);
  const swipeState = useHorizontalSwipe(ref, {
    threshold: 100,
    preventDefault: false,
    passive: true,
  });
  const [isSlided, setIsSlided] = useState(false);
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useState(true);
  const [banners, setBanners] = useState<IBanner[]>([]);

  const bannerStore = useStore(useBannerStore, (state) => state);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchData = async () => {
      const fetchedBanners = await bannerStore.getBanners();
      setBanners(fetchedBanners);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isSlided || !indexActive) {
      return;
    }
    setIsSlided(true);
  }, [indexActive, isSlided]);

  useEffect(() => {
    if (swipeState.swiping || !swipeState.direction || !swipeState.count) {
      return;
    }
    swipeState.direction === "left" && handleClickNext();
    swipeState.direction === "right" && handleClickPrev();
  }, [swipeState.direction, swipeState.swiping, swipeState.count]);

  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 15000 : 999999
  );

  const handleAutoNext = () => {
    setIndexActive((state) => (state >= banners.length - 1 ? 0 : state + 1));
  };

  const handleClickNext = () => {
    setIndexActive((state) => (state >= banners.length - 1 ? 0 : state + 1));
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => (state === 0 ? banners.length - 1 : state - 1));
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };

  const renderDots = () => {
    return (
      <div className="absolute bottom-4 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 z-20 flex justify-center">
        {banners.map((_, index) => {
          const isActive = indexActive === index;
          return (
            <div
              key={index}
              onClick={() => {
                setIndexActive(index);
                handleAfterClick();
              }}
              className={`relative px-1 py-1.5 cursor-pointer`}
            >
              <div
                className={`relative w-20 h-1 shadow-sm rounded-md bg-white`}
              >
                {isActive && (
                  <div
                    className={`nc-LandingHeroItem__dot absolute inset-0 bg-slate-900 rounded-md ${
                      isActive ? " " : " "
                    }`}
                  ></div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderItem = (item: IBanner, index: number) => {
    const isActive = indexActive === index;
    if (!isActive) {
      return null;
    }
    return (
      <div className={`relative mx-auto h-[40vh] sm:h-[50vh] lg:h-[60vh] overflow-hidden ${className}`} key={index}>
        <div className="absolute inset-0">
          <Image
            src={isMobile ? item.banner_url_mobile : item.banner_url}
            alt="Hero banner"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            quality={100}
            priority
          />
        </div>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
          <div className="flex space-x-2">
            <div>{renderDots()}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative" ref={ref}>
      {banners.map((item, index) => renderItem(item, index))}
      <button
        type="button"
        className="absolute inset-y-px end-0 px-10 hidden lg:flex items-center justify-center z-10 text-slate-700"
        onClick={handleClickNext}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={0.6}
          stroke="currentColor"
          className="h-12 w-12"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
      <button
        type="button"
        className="absolute inset-y-px start-0 px-10 hidden lg:flex items-center justify-center z-10 text-slate-700"
        onClick={handleClickPrev}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={0.6}
          stroke="currentColor"
          className="h-12 w-12"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          />
        </svg>
      </button>
    </div>
  );
};

export default LandingHero;